import React, { useState } from 'react';
import 'react-notifications/lib/notifications.css';

function Post() {

  return (
    <div>
     <header className="header" id="header">
            <nav className="navbar navbar-uts">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-uts">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="https://megaschool.edu.vn">
                            <img src="img/logo.svg" className="logo" alt=""/>
                        </a>
                        <ul className="nav navbar-nav navbar-profile visible-xs-block">
                            <li className="dropdown">
                                <a href="https://megaschool.edu.vn/" data-toggle="dropdown" className="dropdown-toggle">
                                    EN
                                    <b className="caret"></b>
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="https://megaschool.edu.vn/">
                                            <span>VN</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-collapse-uts">
                        <ul className="nav navbar-nav navbar-right navbar-hotline hidden-xs">
                            <li className="hotline">
                                <a href="tel:1900 234 582">1900 234 582</a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right navbar-lang hidden-xs">
                            <li>
                                <a className="vietnamese" href="https://megaschool.edu.vn/">
                                    VN
                                </a>
                            </li>
                            <li>
                                <a className="english" href="https://megaschool.edu.vn/">
                                    EN
                                </a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right navbar-uts navscroll">
                            <li className="active">
                                <a href="https://megaschool.edu.vn">Trang chủ</a>
                            </li>
                            <li><a href="https://megaschool.edu.vn/#news">Tin tức</a></li>
                            <li><a href="https://megaschool.edu.vn/#contact">Liên hệ</a></li>
                            <li className="login"><a href="https://online.megaschool.edu.vn">Đăng nhập</a></li>
                        </ul>
                        <div className="hidden-lg hidden-md hidden-sm" id="need-support">
                            <h3 className="need-support-title">Cần hỗ trợ?</h3>
                            <ul className="need-support">
                                <li>
                                    <a href="tel:1900 234 582">
                                        <i className="fa fa-phone-square"></i> 1900 234 582
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <div><h2 style={{marginTop: '17%', marginLeft: '20%', width: '60%'}}>ỨNG DỤNG HỌC LIỆU SỐ TRONG GIẢNG DẠY TẠI TRƯỜNG
    </h2></div>

    <article style={{marginLeft: '10%', marginRight: '10%'}}><p>Sử dụng học liệu số trong việc dạy và học đang là xu hướng được ngành giáo dục quan tâm, chú trọng. Các bài giảng, tài liệu số không chỉ dành riêng cho phương pháp học trực tuyến mà có thể sử dụng cho giảng dạy trực tiếp tại trường, giúp thầy cô tiết kiệm công sức chuẩn bị giáo án, mang đến cho học sinh tiết học sinh động, hấp dẫn, tăng tính tương tác giữa thầy và trò.</p>
      <p>Vậy học liệu số là gì và làm cách nào để ứng dụng học liệu số vào giảng dạy tại trường học? Tất cả sẽ được giải đáp trong buổi Hội thảo “Học liệu số và ứng dụng trong giảng dạy tại trường học” do MegaSchool tổ chức trên Cộng đồng Nhà giáo 4.0.</p>
      <p>Workshop có sự tham gia của Thạc sĩ Nguyễn Đình Tĩnh - Trưởng phòng Công nghệ Thông tin, Giáo vụ, Khảo thí Hệ thống giáo dục Alpha và Ông Dương Văn Hùng - Giáo viên K12 trường học thông minh MegaSchool. Cả hai diễn giả đều có nhiều năm kinh nghiệm trong việc xây dựng và ứng dụng học liệu vào việc giảng dạy trực tiếp.
      </p>
      <p>Buổi hội thảo mang đến những thông tin bổ ích:
      </p>
      <p>👉Tầm quan trọng của học liệu số trong giảng dạy
      </p>
      <p>👉Khai thác các công cụ hỗ trợ để xây dựng nguồn học liệu đa dạng (hình ảnh, video, tài liệu trực tuyến…)
      </p>
      <p>👉Cách ứng dụng học liệu số vào bài giảng trực tiếp tại trường.
      </p>
      <p>Kính mời quý Thầy/Cô tham dự hội thảo “Học liệu số và ứng dụng trong giảng dạy tại trường học” theo thông tin như sau:
      </p>
      <p>Link đăng ký: <a href="https://forms.gle/xct7YXte96euNLwd7">https://forms.gle/xct7YXte96euNLwd7 </a>
      </p>
      <p>📌Trực tuyến qua Zoom và livestream tại Cộng đồng Nhà giáo 4.0
      </p>
      <p>📌Thời gian: 09:30 - 10:30, Thứ 7 ngày 23/04/2022
      </p><img src="img/img-post-ung-dung-hoc-lieu-so.png" style={{margin: 'auto', width: '100%', display: 'block', maxWidth : '200%', height: 'auto'}} />
    </article>

  <footer className="footer">
    <div className="footer-primary">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-7 col-xs-12">
                    <div className="widget logo_footer">
                        <a href="https://megaschool.edu.vn">
                            <img src="img/logo_footer.svg" className="img-responsive" alt=""/>
                        </a>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 col-xs-12">
                    <div className="widget office">
                        <div className="address">
                            <h4 className="text-uppercase">ĐỊA CHỈ VĂN PHÒNG</h4>
                            <p><strong>TP.HỒ CHÍ MINH</strong></p>
                            <p><span>Địa chỉ</span>Tầng 19, Phòng 1901 Saigon Trade Center – Số 37 Tôn Đức Thắng, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh, Việt Nam
                            </p>
                            <p><span>Hotline</span>1900 234 582</p>
                            <p><span>Email</span><a href="mailto:info@megaschool.edu.vn">info@megaschool.edu.vn</a></p>
                        </div>
                        <div className="work_at">
                            <p><strong>Giờ làm việc</strong></p>
                            <p style={{marginBottom: 0, paddingBottom:0}}>Thứ 2 - Thứ 6 </p>
                            <p style={{marginTop:0, paddingTop:0}}>08h:30 - 17h:30</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="copyright">
                        <p>Copyright ©2021 All rights reserved | megaschool.edu.vn</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</div>
  )
}

export default Post;
